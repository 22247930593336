@use '@carbon/react/scss/spacing' as *;
@use '@carbon/react/scss/type' as *;

.ikigai-diagram {
  display: block;
  margin-left: auto;
  margin-right: auto;
  width: 80%;
  }

.ikigai-page {
  padding-top: $spacing-07;
}

.ikigai-page__heading {
  @include type-style('productive-heading-04');
}

.ikigai-page__p {
  margin-top: $spacing-06;
  margin-bottom: $spacing-08;
}

.ikigai-page-content TextArea {
  margin-bottom: $spacing-07;
}

.ikigai-page-content label{
  @include type-style('heading-compact-01');
}

.ikigai-loader {
  margin: $spacing-07 auto 0;
}